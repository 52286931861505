//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Country from "../../../components/management/localization/Country.vue";
import Currency from "../../../components/management/localization/Currency.vue";
import City from "../../../components/management/localization/City.vue";

//import axios from "axios";

/**
 * Rbac component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Country,
    Currency,
    City
},
  data() {
    return {
      currencyList: [],
      countryList: [],
      title: this.$t('menuitems.management.list.localization'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.localization'),
          active: true
        }
      ],
    };
  },
  validations: {

  },
  computed: {

  },
  methods: {
    setCurrencyList(list){
      this.currencyList = list;
    },
    setCountryList(list){
      this.countryList = list;
    }
  },
  created() {
    
  }
};
