//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {maxLength, minLength, minValue, maxValue, required} from "vuelidate/lib/validators";
import axios from "axios";
import Popup from "@/components/Popup";

export default {
  name: "Currency",
  components: {Popup},
  data(){
    return {
      list: [],
      newCurrency: {
        name: '',
        digital: '',
        letter: ''
      },
      submitted: false,
      deletedId: 0,
      deletePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
    }
  },
  props: {

  },
  watch: {
    list: function(val){
      this.$emit('updateList', val);
    }
  },
  computed: {

  },
  validations: {
    newCurrency: {
      name: { required, minLength: minLength(2), maxLength: maxLength(250) },
      letter: { required, minLength: minLength(1), maxLength: maxLength(4) },
      digital: { required, minValue: minValue(1), maxValue: maxValue(9999) }
    }
  },
  methods: {
    submitNewCurrency(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newCurrency.$touch();
      if(!this.$v.newCurrency.$invalid){
        let formData = new FormData();
        formData.append("name", this.newCurrency.name);
        formData.append("digital", this.newCurrency.digital);
        formData.append("letter", this.newCurrency.letter);

        axios
            .post(`/v1/localization/add-currency`, formData)
            .then(resp => {
              this.list = resp.data;
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 2,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    deleteCurrency(id){
      this.deletedId = id;
      this.deletePopup.show = true;
    },
    closeDeletePopup(){
      this.deletePopup.show = false;
    },
    deletePopupSubmit(){
      let formData = new FormData();
      formData.append("id", this.deletedId);

      axios
          .post(`/v1/localization/delete-currency`, formData)
          .then(() => {
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.deleted'),
              time: 2,
              color: "success"
            });
            this.getAll();
            this.closeDeletePopup();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getAll(){
      axios
          .get(`/v1/localization/get-currency`)
          .then(resp => {
            this.list = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getAll();
  }

};
